"use client";

import CloudImage from "@/lib/components/CloudImage";
import { CLOUDINARY_FOLDER } from "@/lib/constants/cloudinary";
import GuestRegistrationForm from "@/lib/components/Forms/Registration/GuestRegistrationForm";
import Text from "@/lib/components/Text";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { getUrl, Urls } from "@/lib/constants/urls";
import { useSearchParams } from "next/navigation";


export default function RegistrationPage() {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "registration");
    const params = useSearchParams();

    const getSuccessRedirectUrl = () => {
        if (params.has("next") && params.get("next")) {
            return params.get("next")!;
        }
        return getUrl(Urls.userProfile.index, lang);
    };

    return (
        <main className="w-full h-screen flex flex-col md:flex-row md:justify-center relative">
            <div className="w-1/2 hidden md:block pt-header-small lg:pt-header">
                <div className="w-full h-full relative">
                    {/* Image on the left side  */}
                    <CloudImage
                        src={`${CLOUDINARY_FOLDER}/static/registration.jpg`}
                        alt=""
                        className="object-cover brightness-75"
                        sizes="100vh"
                    />
                    <Text
                        as="h1-seo"
                        className="absolute z-10 text-white font-extrabold top-56 left-1/2 -translate-x-1/2 leading-snug"
                    >
                        {t("create-a")}
                        <br />
                        {t("free-account")}
                    </Text>
                </div>
            </div>
            {/* Registration form on the right side */}
            <div className="lg:w-1/2 py-2 lg:px-14 px-4 mt-4 lg:mt-0 flex justify-center pt-header-small lg:pt-header">
                <div className="w-full flex items-center justify-center">
                    <GuestRegistrationForm onSuccessRedirectUri={getSuccessRedirectUrl()} />
                </div>
            </div>
        </main>
    );
}
