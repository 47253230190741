"use client";

import { authAtom } from "@/lib/jotai/auth/authStore";
import { LanguageParams } from "@/lib/types/PageProps";
import { useAtom } from "jotai";
import { useRouter } from "next/navigation";
import { ReactNode, useEffect, useState } from "react";
import Text from "../Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import LoginForm from "../Forms/LoginForm";
import { Urls, getUrl } from "@/lib/constants/urls";
import { UserInfo } from "@/lib/jotai/auth/types";
import { checkoutFormsAtom } from "@/lib/jotai/checkout/checkoutStore";
import { getUserInfo } from "@/lib/hooks/useUserInfo";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import RegistrationPage from "@/app/[lang]/(no-footer)/registration/RegistrationPage";

type LoginGuardProps = {
    children: ReactNode;
    notLoggedInAction?: "REDIRECT_TO_HOME" | "SHOW_AUTH_FORM";
    showBorder?: boolean;
} & LanguageParams;

const LoginGuard = ({
    lang,
    notLoggedInAction = "REDIRECT_TO_HOME",
    children,
    showBorder = false,
}: LoginGuardProps) => {
    const [auth, setAuth] = useAtom(authAtom);
    const router = useRouter();
    const [showRegistration, setShowRegistration] = useState(true);
    const [, setCheckoutForms] = useAtom(checkoutFormsAtom);


    /**
     * Checking out if the user is logged in otherwise redirect
     * him to the homepage or login page (future implementation)
     */
    useEffect(() => {
        getUserInfo(auth, setAuth, setCheckoutForms, router, () => {
            if (notLoggedInAction === "REDIRECT_TO_HOME") {
                router.push(getUrl(Urls.home.index, lang));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, router.push, setAuth, notLoggedInAction]);

    if (auth.isLoggedIn) {
        return children;
    }

    const loader = (
        <div className="flex h-screen">
            <div className="m-auto">
                <div className="ml-auto mr-auto">
                    <FontAwesomeIcon icon={faSpinner} size="4x" className="ml-2 animate-spin text-center" />
                    <Text as="h1-seo" className="inline-block p-6">
                        Loading...
                    </Text>
                </div>
            </div>
        </div>
    );

    const onRegisterButtonClick = () => {
        setShowRegistration(true);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onLoginButtonClick = () => {
        setShowRegistration(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onGoogleRegistrationSuccess = (userInfo: UserInfo | null, error: Error | null) => {
        if (error) {
            setAuth({
                ...auth,
                isLoggedIn: false,
                userInfo: auth.userInfo,
            });
        } else {
            GoogleTagManagerEvents.submitRegisterPageInfo(
                lang,
                "checkout",
                auth.isLoggedIn ? auth.userInfo._id : undefined,
                "Google",
            );
            setAuth({
                ...auth,
                isLoggedIn: true,
                userInfo: userInfo!,
            });
            setShowRegistration(false);
        }
    };

    // We need to figure what to show the user here while it loads an checks the user data.
    return notLoggedInAction === "REDIRECT_TO_HOME" ? (
        loader
    ) : (
        <div className={` grow w-full max-width:100vw ${showBorder ? "border-2 border-b rounded-md" : ""}`}>
            {showRegistration ? (
                <RegistrationPage
                    /*isOnCheckout={true}
                    onGoToLoginButtonClick={onLoginButtonClick}
                    onGoogleRegistrationSuccess={onGoogleRegistrationSuccess}*/
                />
            ) : (
                <LoginForm onGoToRegisterButtonClick={onRegisterButtonClick} />
            )}
        </div>
    );
};

export default LoginGuard;
